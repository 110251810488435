import React from "react";
import Input from "../../../../../components/Supervisor/input";
import classes from "../editor.module.scss";
import Select from "react-select";

const ChapterSettingsForm = ({page,updatePageData, chapter, updateSelectedRule}) => {

  const iconTypes = [{value:'ImageAndText',label:"Image & Text"},
                     {value:'FlipCards',label:"Flipcard"},
                     {value:'Hotspots',label:"Hotspots"},
                     {value:'CardSort',label:"CardSort"},
                     {value:'Carousel-Block',label:"Carousel"}
                    ];

  const themeTypes = [{ value:'Yellow', 
                        label:"KS Default - Yellow", 
                        iconColor: "#28353C", 
                        iconBackground: "linear-gradient(95.67deg, #FFC200 0.49%, #EEB954 47.62%, #E0D80E 100.29%)", 
                        textColor: "#28353C", 
                        background: "linear-gradient(95.67deg, #FFC200 0.49%, #EEB954 47.62%, #E0D80E 100.29%)", 
                        ctaColor: "#FFF", 
                        ctaBorder: {
                            type: "solid",
                            color: "#FFF"
                        },
                        progressColor: "#28353C",
                        progressEmptyColor: "#FFF",
                        border: "linear-gradient(to right, #FFC200, #E0D80E)"
                      },
                      { value:'Red', 
                        label:"KS Default - Red", 
                        iconColor: "#FFF", 
                        iconBackground: "#E43D8D", 
                        textColor: "#FFF", 
                        background: "#E43D8D", 
                        ctaColor: "#D33581", 
                        ctaBorder: {
                            type: "solid",
                            color: "#FFF"
                        },
                        progressColor: "#D33581",
                        progressEmptyColor: "#FFF",
                        border: "#E43D8D"
                      },
                      { value:'Green', 
                        label:"KS Default - Green", 
                        iconColor: "#28353C", 
                        iconBackground: "#7DD4B5", 
                        textColor: "#28353C", 
                        background: "#7DD4B5", 
                        ctaColor: "#5BD2A8", 
                        ctaBorder: {
                            type: "solid",
                            color: "#28353C"
                        },
                        progressColor: "#28353C",
                        progressEmptyColor: "#3CB88C",
                        border: "#7DD4B5"
                      },
                      { value:'Blue', 
                        label:"KS Default - Blue", 
                        iconColor: "#FFF", 
                        iconBackground: "#105BFB", 
                        textColor: "#FFF", 
                        background: "#105BFB", 
                        ctaColor: "#0C50E1", 
                        ctaBorder: {
                            type: "solid",
                            color: "#FFF"
                        },
                        progressColor: "#FFF",
                        progressEmptyColor: "#0C50E1",
                        border: "#105BFB"
                      },
                      { value:'Purple', 
                        label:"KS Default - Purple", 
                        iconColor: "#FFF", 
                        iconBackground: "#7232F9", 
                        textColor: "#FFF", 
                        background: "#7232F9", 
                        ctaColor: "#621EF1", 
                        ctaBorder: {
                            type: "solid",
                            color: "#FFF"
                        },
                        progressColor: "#FFF",
                        progressEmptyColor: "#621EF1",
                        border: "#7232F9"
                      },
                    ];
   
  const updateChapterIcon = (icon) => {
  
      var chapterUpdated = {...chapter};
      chapterUpdated.iconType = icon;

      //now put it back
      var allChapters = [...page?.data.rules];

      for(var i=0; i<allChapters.length; i++)
      {
          if(allChapters[i].id == chapter.id)
            allChapters[i] = chapterUpdated;
      }

      var updatedPageData = {...page,data:{...page.data,rules:allChapters}}

      console.log('updateChapterIcon',updatedPageData);

      updatePageData({key:'rules',value:allChapters});
      updatePageData({key:'rules',value:allChapters},true); //preview only

      updateSelectedRule(chapterUpdated);
  }

  const updateChapterTheme = (val) => {
  
    var chapterUpdated = {...chapter};
    
    //set the selected theme
    chapterUpdated.iconColor = val.iconColor;
    chapterUpdated.iconBackground = val.iconBackground;
    
    chapterUpdated.textColor = val.textColor;
    chapterUpdated.background = val.background;
    chapterUpdated.border = val.border;
    
    chapterUpdated.ctaColor = val.ctaColor;
    chapterUpdated.ctaBorder = val.ctaBorder;
    
    chapterUpdated.progressColor = val.progressColor;
    chapterUpdated.progressEmptyColor = val.progressEmptyColor;
    


    //now put it back
    var allChapters = [...page?.data.rules];

    for(var i=0; i<allChapters.length; i++)
    {
        if(allChapters[i].id == chapter.id)
          allChapters[i] = chapterUpdated;
    }

    var updatedPageData = {...page,data:{...page.data,rules:allChapters}}

    console.log('updateChapterIcon',updatedPageData);

    updatePageData({key:'rules',value:allChapters});
    updatePageData({key:'rules',value:allChapters},true); //preview only

    updateSelectedRule(chapterUpdated);
}


  return <div>
        
        <br />
        <h4 className={classes.formHeading}>Chapter Settings</h4>

        <p>Chapter Icon:</p>
        <Select
            styles={{
                control: () => ({
                width: "100%",
                height: 44,
                display: "flex",
                background: "#f8f8f8",
                paddingLeft: "10px",
                color: "#40507e",
                border: "1px solid #E0E0E0",
                boxSizing: "border-box",
                borderRadius: "6px",
                boxShadow: "1px 1px 1px rgba(0, 0, 0, 0.25)",
                fontFamily: "Open Sans",
                fontSize: "14px",
                fontWeight: "bold",
                }),
            }}
            placeholder={"Correct Option"}
            onChange={(val) => {
                updateChapterIcon(val.value);
            }}
            tabSelectsValue={''}
            value={iconTypes.filter(
                (option) => option.value === chapter?.iconType
              )}
            options={iconTypes}
            isSearchable={false}
        />

        <br />

        <p>Select & apply a color theme:</p>
        <Select
            styles={{
                control: () => ({
                width: "100%",
                height: 44,
                display: "flex",
                background: "#f8f8f8",
                paddingLeft: "10px",
                color: "#40507e",
                border: "1px solid #E0E0E0",
                boxSizing: "border-box",
                borderRadius: "6px",
                boxShadow: "1px 1px 1px rgba(0, 0, 0, 0.25)",
                fontFamily: "Open Sans",
                fontSize: "14px",
                fontWeight: "bold",
                }),
            }}
            placeholder={"Select to apply a color theme"}
            onChange={(val) => {
                updateChapterTheme(val);
            }}
            tabSelectsValue={''}
            value={themeTypes.filter(
                (option) => option?.background === chapter?.background
              )}
            options={themeTypes}
            isSearchable={false}
        />




  </div>;
};

export default ChapterSettingsForm;