import React, { useEffect, useState, useCallback } from "react";
import RouteLeavingGuard from "../../../../components/UI/routeGuard";

import Menu from "components/Supervisor/menu";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { getInductionDetailReq, updateInductionReq, createAssessmentReq } from "store/supervisor/actions";
import {
  makeSelectLoading,
  makeSelectUsers,
  makeSelectInductionDetail,
  makeSelectAssessmentDetails
} from "store/supervisor/selector";

import {
  makeSelectLanguages,
} from "store/language/selector";

import { isNil } from "lodash";
import ReactLoading from "react-loading";
import classes from "./editor.module.scss";
import queryString from "query-string";

import { getComponent } from "../../../../components/UI/section/componentHandler";
import Input from "../../../../components/Supervisor/input";
import Select from "react-select";

import pageIcon from "./icons/page_icon.png";
import pageIconSelected from "./icons/induction_check.svg";
import pageItemHeaderIcon from "./icons/pageitem_header.png";
import IconComponent from "../../../../components/UI/IconComponent";
import { Header } from "components";

//Forms for different page types
import Prequalification from "./Forms/prequalification";
import Introduction from "./Forms/introduction";
import Video from "./Forms/video";
import AllChaptersCompleteForm from "./Forms/allChaptersComplete";
import InductionCompleteForm from "./Forms/inductionComplete";
import RulesForm from "./Forms/rules";
import ChaptersForm from "./Forms/chapters";
import AssessmentForm from "./Forms/assessment"
import ChapterSettingsForm from "./Forms/chapterSettings"


//pages for preview
import Introductory from "../../../Introductory";
import Welcome from "../../../Welcome";
import LookAround from "../../../LookAround";
import Induction from "../../../Induction";
import AllChaptersComplete from "../../../AllChaptersComplete";
import SiteRules from "../../../SiteRules";
import Complete from "../../../Complete";
import AssesmentPage from "../../../Assessment";
import PostLogin from "../../../PostLogin";

//components
import PageItem from "./Components/PageItem";

import { select } from "redux-saga/effects";

import Modal from "components/Supervisor/modal";
import CropModal from "components/Supervisor/modal/cropper";

import { previousFriday } from "date-fns";

import { useDrop } from 'react-dnd';
import update from 'immutability-helper'


const InductionEditor = ({
  history,
  loading,
  induction,
  getInductions,
  updateInduction,
  languages,
  createAssessment,
  assessmentDetails
}) => {
  const id = queryString.parse(window.location.search).id;

  const [editData, setEditData] = useState(false); //used for route guard when changing page
  const [isThereUnsavedEdits, setIsThereUnsavedEdits] = useState(false);
  const [itemToMoveTo, setItemToMoveTo] = useState({}); //This is used when user confirms the modal
 
  //Below are to update the induction content
  const [selectedPage, setSelectedPage] = useState({title: ''});
  const [selectedRule, setSelectedRule] = useState({title: ''});
  const [selectedSection, setSelectedSection] = useState({title: ''});
  
  //Below are only to update the preview
  const [selectedPagePreview, setSelectedPagePreview] = useState({title: ''});


  const [languageOptions, setLanguageOptions] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState({code: 'en', name: "English"});
  const [translations, setTranslations] = useState({});
  const [previewCounter, setPreviewCounter] = useState(1);

  //creating new pages
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(0);
  const [pageType, setPageType] = useState();
  const [inductionPageData, setInductionPageData] = useState([]);

  //image crop
  const [isCropModalOpen, setIsCropModalOpen] = useState(0);
  const [imageToCrop, setImageToCrop] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null)
  
  
  //for drag & drop
 /* 
  const [{ canDrop, isOver }, drop] = useDrop(() => ({
    // The type (or types) to accept - strings or symbols
    accept: 'Page',
    hover(item, monitor) {
      var updatedPageData = [];

      if(inductionPageData.length == 0)
        updatedPageData = [...induction.data.pages];
      else
        updatedPageData = [...inductionPageData];

       const currentPos = monitor.getClientOffset();
       //console.log('hovering',document.querySelectorAll('#div_pageItemWrapper > div'));

       const all_items = document.querySelectorAll('#div_pageItemWrapper > div');

       //reset margins first
       for(var i = 0; i < all_items.length; i++)
       {
           all_items[i].style.marginTop = "0px";
       }

       var foundIndex = -1;
       var currentIndex = -1;
       
       console.log('hovering - updatedData',updatedPageData);

       for(var i = 0; i < all_items.length; i++)
       {
          if(item.item.id == updatedPageData[i]?.id)
             currentIndex = i;
       }
       
       for(var i = 0; i < all_items.length; i++)
       {
          const bound_rect = all_items[i].getBoundingClientRect();
          
          if(bound_rect.y > currentPos.y)
          {
             console.log('hovering - will be in place of item ' + i);
             foundIndex = i;
             break;
          }
             
          
          //console.log('hovering - item - ' + i,all_items[i].getBoundingClientRect());
       }

       if(foundIndex >= 0)
       {
          all_items[foundIndex].style.marginTop = "50px";
       }

       console.log('hovering - currentIndex ',currentIndex + ' foundIndex ' + foundIndex);
       
      
    },
    drop(item, monitor)
    {
      var updatedPageData = [];
      var foundIndex = -1;
      var currentIndex = -1;

      if(inductionPageData.length == 0)
        updatedPageData = [...induction.data.pages];
      else
        updatedPageData = [...inductionPageData];

       const currentPos = monitor.getClientOffset();

       const all_items = document.querySelectorAll('#div_pageItemWrapper > div');
       for(var i = 0; i < all_items.length; i++)
        {
           all_items[i].style.marginTop = "0px";

           if(item.item.id == updatedPageData[i]?.id)
            currentIndex = i;
        }


        for(var i = 0; i < all_items.length; i++)
        {
             const bound_rect = all_items[i].getBoundingClientRect();
             
             if(bound_rect.y > currentPos.y)
             {
                console.log('hovering - will be in place of item ' + i);
                foundIndex = i;
                break;
             }
        }

        console.log('dropped - currentIndex ',currentIndex + ' foundIndex ' + foundIndex);
        //handle the data change
        if(foundIndex >= 0 && currentIndex >= 0 && foundIndex != currentIndex)
        {
             console.log('dropped - before processs',updatedPageData);
             //first copy and delete the current item from the array
             var itemCopy = {...updatedPageData[currentIndex]}
             updatedPageData.splice(currentIndex, 1);

             console.log('dropped - copied item',itemCopy);
             //now add the copy to the array in given index
             updatedPageData.splice(foundIndex, 0, itemCopy)
              
                 
   
              //updatedPageData[currentIndex] = updatedPageData[foundIndex];
              //updatedPageData[foundIndex] = item;
   
              console.log('dropped',updatedPageData);
              setInductionPageData(updatedPageData);
   
        }        

    },
    // Props to collect
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop()

    })
  }))
*/

  const moveItem = useCallback(
    (dragIndex, hoverIndex) => {
      const dragCard = inductionPageData[dragIndex]
      setInductionPageData(
        update(inductionPageData, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragCard],
          ],
        }),
      )
    },
    [inductionPageData],
  )


  useEffect(() => {
    if (languages?.length) {
      const dfList = languages.map((item) => {
        return {
          value: item.code,
          label: item.name,
        };
      });

      setLanguageOptions(dfList);

      //set the translation for the selected language
      var newTransalation = {}
      for(var i = 0; i < languages.length; i++)
      {
          if(induction?.translations)
          {
             var parsed = {};
             try{
               parsed = JSON.parse(induction?.translations);
               //console.log('parsed',parsed);
             }
             catch(err) {
              console.log('cannot parse json');
            }

             if(parsed[languages[i].code])
                newTransalation[languages[i].code] = {...JSON.parse(languages[i]?.data),...parsed[languages[i].code]}
          }
          else
            newTransalation[languages[i].code] = JSON.parse(languages[i]?.data);
      }

      setTranslations(newTransalation);

      console.log('initial translations',newTransalation);
      //console.log('induction translations',JSON.parse(induction?.translations)['en']);

    }
  }, [languages]);


  useEffect(() => {
    getInductions({id:id});
  }, []);

  useEffect(() => {
    console.log('induction:',induction);

    //set the first page as selected
    if(induction?.data?.pages.length > 0)
       updateSelectedPage(induction.data.pages[0]);
    
    setInductionPageData(induction.data.pages);

    //refresh translations
    setTimeout(function(){
      setPreviewCounter(0);

      setSelectedLanguage({
        code: 'en',
        name: 'English',
      });
    },1000);

  }, [induction]);


  useEffect(() => {
    console.log('assessmentDetails',assessmentDetails);

    if(parseInt(assessmentDetails?.success) == 1 & parseInt(assessmentDetails?.type) == 10)
    {
        //add the assessment id to the page
        var pageData = [...inductionPageData];

        for(var i=0; i < pageData.length; i++)
        {
            if(pageData[i].type == 'assessment')
            {
                if(pageData[i]['assesmentId'] == 0 || pageData[i]['assessmentId'] == 0)
                {
                   delete pageData[i]['assesmentId']; //this is the old way with a typo
                   pageData[i]['assessmentId'] = assessmentDetails?.id;
                   pageData[i]['data'] = {...pageData[i]['data'], assessmentId: assessmentDetails?.id};
                }
                   
            }
        }

        setInductionPageData(pageData);

    }

  }, [assessmentDetails]);


  //used for updating page data to reflect the current selected language translations
  function refreshTranslationData(pageData = null)
  {
    //update the title of the page
    var updatedPageData = {}
    
    if(pageData)
      updatedPageData = {...pageData};
    else  
      updatedPageData = {...selectedPagePreview};

    console.log('language changed',updatedPageData);

    //change the title
    if(selectedPagePreview?.title_id)
       updatedPageData.title = translations[selectedLanguage.code][selectedPagePreview?.title_id];

    //change the subtitle
    if(selectedPagePreview?.data?.subtitle_id)
       updatedPageData.data = {...updatedPageData.data,subtitle: translations[selectedLanguage.code][selectedPagePreview?.data?.subtitle_id]};
    else
       updatedPageData.data = {...updatedPageData.data,subtitle: ''};


    //now the data based on page type
    if(updatedPageData?.type == 'post_login')
    {
        var questions = [...updatedPageData?.data?.questions];

        for(var i = 0; i < questions.length; i++)
        {
            if(questions[i].id)
            {
                if(translations[selectedLanguage.code][questions[i].id])
                   questions[i] = {...questions[i],name: translations[selectedLanguage.code][questions[i].id]}
                else
                   questions[i] = {...questions[i],name: ''}
            }
        }

        updatedPageData.data = {...updatedPageData.data,'questions': questions}

    }
    else if(updatedPageData?.type == 'introduction')
    {

      if(updatedPageData?.data?.content_id)
      {
          if(translations[selectedLanguage.code][updatedPageData?.data?.content_id])
             updatedPageData.data = {...updatedPageData.data, content: translations[selectedLanguage.code][updatedPageData?.data?.content_id]};
          else
             updatedPageData.data = {...updatedPageData.data, content: updatedPageData?.data?.content_id};
      }

      var recommendations = [...updatedPageData?.data?.recommendations];
  
      for(var i = 0; i < recommendations.length; i++)
      {
          if(recommendations[i].text_id)
          {
              if(translations[selectedLanguage.code][recommendations[i].text_id])
                recommendations[i] = {...recommendations[i],text: translations[selectedLanguage.code][recommendations[i].text_id]}
              else
              recommendations[i] = {...recommendations[i],text: ''}
          }
      }
  
      updatedPageData.data = {...updatedPageData.data,'recommendations': recommendations}
  
    }
    else if(updatedPageData?.type == 'all-chapters-complete')
    {
      if(updatedPageData?.data?.content_id)
      {
         if(translations[selectedLanguage.code][updatedPageData?.data?.content_id])
            updatedPageData.data = {...updatedPageData.data, content: translations[selectedLanguage.code][updatedPageData?.data?.content_id]};
         else
            updatedPageData.data = {...updatedPageData.data, content: updatedPageData?.data?.content_id};
      }

      if(updatedPageData?.data?.subcontent_id)
      {
         if(translations[selectedLanguage.code][updatedPageData?.data?.subcontent_id])
            updatedPageData.data = {...updatedPageData.data, subcontent: translations[selectedLanguage.code][updatedPageData?.data?.subcontent_id]};
         else
            updatedPageData.data = {...updatedPageData.data, subcontent: updatedPageData?.data?.subcontent_id};
      }
    }
    else if(updatedPageData?.type == 'site-rules')
    { 
        
      var rules = [...updatedPageData?.data?.rules];
  
      for(var i = 0; i < rules.length; i++)
      {
          if(rules[i].content_id)
          {
              if(translations[selectedLanguage.code][rules[i].content_id])
                rules[i] = {...rules[i],content: translations[selectedLanguage.code][rules[i].content_id]}
              else
                rules[i] = {...rules[i],content: rules[i].content}
          }
      }
  
      updatedPageData.data = {...updatedPageData.data,'rules': rules}
    }
    else if(updatedPageData?.type == 'complete')
    {
      if(updatedPageData?.data?.content_id)
      {
          if(translations[selectedLanguage.code][updatedPageData?.data?.content_id])
            updatedPageData.data = {...updatedPageData.data, content: translations[selectedLanguage.code][updatedPageData?.data?.content_id]};
          else
            updatedPageData.data = {...updatedPageData.data, content: updatedPageData?.data?.content_id};
      }
    }
    else if(updatedPageData?.type == 'rules')
    {
        if(selectedSection)
        {
            if(translations[selectedLanguage.code][selectedSection?.title_id])
               setSelectedSection({...selectedSection, title: translations[selectedLanguage.code][selectedSection?.title_id]})
        }
    }  

    
    //console.log('updatedPageData',updatedPageData);
 

    setSelectedPagePreview(updatedPageData);

  }

  useEffect(() => {
    console.log('language changed',selectedLanguage);

    if(previewCounter > 0)
      return;

    refreshTranslationData();

    setPreviewCounter(2);

  }, [selectedLanguage]);



  function updateSelectedPage(item)
  {
    if(editData)
    {
      setIsThereUnsavedEdits(true);
      setItemToMoveTo(item);
    }
    else
    {
      setIsThereUnsavedEdits(false);
     
      setSelectedPage(item);
      setSelectedPagePreview(item); //to preview
      setSelectedRule({title: ''}); //reset
      setSelectedSection({title: ''}); //reset
      
      //refresh translations
      setPreviewCounter(0);

      setSelectedLanguage({
          code: 'en',
          name: 'English',
      });

    }
  }

  function updateSelectedRule(item)
  {
     setSelectedRule(item);
     setSelectedSection({title: ''}); //reset
     //console.log(item);
  }

  function updateSelectedSection(item)
  {
     setSelectedSection(item);

     //refresh translations
     setPreviewCounter(0);
     setSelectedLanguage({
         code: 'en',
         name: 'English',
     });

     //console.log(item);
  }  

  function updateTranslation(language,key,content)
  {
      var newTranslation = {...translations}
      newTranslation[language][key] = content;
      setTranslations(newTranslation);

      setEditData(true); //trigger change checker

      console.log('translations',newTranslation);
  }

  function handleSubmit()
  {
     //This is our gigantic induction save function so we will go step by step
     
    //update page data
    var data = { ...induction.data }
    var pageData = [...inductionPageData]

    for(var i = 0; i < inductionPageData.length; i++)
    {
        if(inductionPageData[i].id == selectedPage.id)
        {
            //console.log('found id: '+ selectedPage.id);
            pageData[i] = {...selectedPage};
        }


        if(inductionPageData[i].data.rules && inductionPageData[i].type == 'rules')
        {
          var ruleData = [...inductionPageData[i].data.rules]
          for(var j = 0; j < inductionPageData[i].data.rules.length; j++)
          {
              if(inductionPageData[i].data.rules[j].id == selectedRule.id && selectedRule.id > 0)
              {
                  //console.log('found id: '+ selectedRule.id);
                  ruleData[j] = {...selectedRule};
              }
              
              if(inductionPageData[i].data.rules[j]?.sections)
              {
                
                var updatedSections = [...inductionPageData[i].data.rules[j].sections];
                for(var k = 0; k < updatedSections.length; k++)
                {
                    if(inductionPageData[i].data.rules[j].sections[k]?.header_id == selectedSection?.header_id
                      && inductionPageData[i].data.rules[j].sections[k]?.title_id == selectedSection?.title_id
                    )
                    {
                      updatedSections[k] = selectedSection;
                    }

                    ruleData[j] = {...ruleData[j],sections:updatedSections};

                }
              }

          }
          
          //console.log('ruleData', ruleData);
          //doesn't work for now
          var updatedData = {...pageData[i],data:{...pageData[i].data, rules: ruleData}}
          pageData[i] = {...updatedData};
          //pageData[i] = {...pageData[i], 'rules': ruleData};

        }
    }

    data.pages = pageData;


    console.log('data.pages',data.pages);
    
    //DO NOT OPEN UNTIL YOU ARE SURE DATA IS ASSEMBLED CORRECTLY!
    //translations only for now
    updateInduction({'id': id, 'data': JSON.stringify(data), 'translations': JSON.stringify(translations)})

    //reset the status
    setEditData(false);
    setIsThereUnsavedEdits(false);

  }
  
  //function that is called by the special forms
  function updatePageData(val,isPreview=false)
  {
    setEditData(true); //trigger change checker

    console.log('update page data called',val);

    var currentData = {};

    if(isPreview)
       currentData = {...selectedPagePreview}
    else
       currentData = {...selectedPage}

    if(val?.key)
    {
      var updatedPage = {...currentData, data:{...currentData.data, 
        [val?.key]: val?.value
      }}
    }
    else
    {
      var updatedPage = {...currentData, data:{...currentData.data, 
                                                image_id: val?.image_id,
                                                image: val?.image
                                              }}
    }
    console.log('updatedPage',updatedPage);
    
    if(isPreview)
      setSelectedPagePreview(updatedPage);
    else
      setSelectedPage(updatedPage);
  }

  function prepareTranslatedHotspots(hotspots)
  {
     var newHotspots = [...hotspots];

     newHotspots = newHotspots.map((item) => { return {
      ...item,
      name: translations[selectedLanguage.code][item?.name_id] ? translations[selectedLanguage.code][item?.name_id]: '-',
      content: translations[selectedLanguage.code][item?.content_id] ? translations[selectedLanguage.code][item?.content_id] : '-'

     } } );

     return newHotspots;
  }

  function prepareTranslatedFlipcards(cards)
  {
    var newCards = [...cards];

    newCards = newCards.map((item) => { return {
      ...item,
      title: translations[selectedLanguage.code][item?.title_id],
      front_title: translations[selectedLanguage.code][item?.front_title_id],
      front: translations[selectedLanguage.code][item?.front_id],
      back_title: translations[selectedLanguage.code][item?.back_title_id],
      back: translations[selectedLanguage.code][item?.back_id]

     } } );

     return newCards;
  }

  function prepareTranslatedCarousel(content)
  {
    var newCards = [...content];

    newCards = newCards.map((item) => { return {
      ...item,
      title: translations[selectedLanguage.code][item?.title_id],
      content:  translations[selectedLanguage.code][item?.content_id] ? translations[selectedLanguage.code][item?.content_id] : '',

     } } );

     return newCards;
  }


  function prepareTranslatedCardsort(content)
  {
    var newCards = [...content];

    newCards = newCards.map((item) => { return {
      ...item,
      name: translations[selectedLanguage.code][item?.name_id] ? translations[selectedLanguage.code][item?.name_id] : '-',
      content:  translations[selectedLanguage.code][item?.content_id] ? translations[selectedLanguage.code][item?.content_id] : '-',
      categories: item?.categories.map((catItem) => { return {...catItem, name: translations[selectedLanguage.code][catItem?.name_id] ? translations[selectedLanguage.code][catItem?.name_id] : '-' }})

     } } );

     return newCards;
  }

  //Change Modal actions
  function closeModal()
  {
     setIsThereUnsavedEdits(false);
  }

  function modalAction()
  {
     //move to the next page without saving
     setIsThereUnsavedEdits(false);
     setEditData(false);
     updateSelectedPage(itemToMoveTo);
     
  }

  //create modal actions
  function closeCreateModal()
  {
     setIsCreateModalOpen(false);
  }

  function createPage()
  {
    var pageData = [...inductionPageData];

    //find the highest id
    var nextId = 0;
    for(var i=0; i < pageData.length; i++)
    {
        if(pageData[i].id > nextId)
           nextId = pageData[i].id;
        
        if(pageData[i].type =='rules')
        {
           for(var j=0; j < pageData[i].data.rules.length; j++)
           {
              if(pageData[i].data.rules[j].id > nextId)
                 nextId = pageData[i].data.rules[j].id;
           }
        }
    }
    nextId +=1;

     var newPageData = {
       id: nextId,
       type: pageType,
       url: '/' + pageType + '-' + nextId,
       title: '',
       title_id: 'induction.' + id + '.page.' + nextId + '.title',
       menu_name: "",
       menu_name_id: 'induction.' + id + '.page.' + nextId + '.menu.name',
       public: false,
       show_in_menu: true,
       data: {
         subtitle: '',
         subtitle_id: 'induction.' + id + '.page.' + nextId + '.subtitle',
       } 
     }

     if(pageType == 'introduction')
     {
        newPageData['data'] = {
          ...newPageData['data'],
          'content': '',
          'content_id': 'induction.' + id + '.page.' + nextId + '.content',
          'image': "https://keepsafe.work/images/02.01_welcome_A.jpeg",
          'recommendations': [
            {
              "icon": "sound",
              "text": "You will need sound for this induction",
              "text_id": 'induction.' + id + '.page.' + nextId + '.recommendation.1'
            },
            {
              "icon": "time",
              "text": "The time required is approx. 7 mins.",
              "text_id": 'induction.' + id + '.page.' + nextId + '.recommendation.2'
            }   
          ]

        }
     }
     else if(pageType == 'all-chapters-complete')
     {
          newPageData['data'] = {
           ...newPageData['data'],
          content: "You now understand our life saving rules. Let us walk you through a few last notes before we let you get on with it!",
          content_id: 'induction.' + id + '.page.' + nextId + '.content',
          subcontent: "Tap the next button below to proceed.",
          subcontent_id: 'induction.' + id + '.page.' + nextId + '.subcontent',
 
         }
     }
     else if(pageType == 'welcome')
     {
         newPageData['data'] = {
           ...newPageData['data'],
           video: [
                    {
                        "language": "eng",
                        "url": "https://vimeo.com/videoId",
                        "transcript": "Lorem ipsum dolor"
                    }
          ],
          video_subtitles: [
            "eng"
          ]
 
         }
     }
     else if(pageType == 'site-rules')
     {
        newPageData['data'] = {
          ...newPageData['data'],
          rules: [
                  {
                      "content": "<p>Lorem ipsum dolor sit amet.</p>",
                      "content_id": 'induction.' + id + '.page.' + nextId + '.content.1',
                      "image": ""
                  },
                  {
                      "content": "<p>Aut mollitia repudiandae qui consequatur nisi qui fugit similique ut omnis nesciunt.</p>",
                      "content_id": 'induction.' + id + '.page.' + nextId + '.content.2',
                      "image": ""
                  },
                  {
                      "content": "<p>Cum iusto vero ut natus vitae in deserunt iure qui rerum consequatur ut temporibus nemo ea iure necessitatibus.</p>",
                      "content_id": 'induction.' + id + '.page.' + nextId + '.content.3',
                      "image": ""
                  },
                ]
        }
     }
     else if(pageType == 'complete')
     {
           newPageData['data'] = {
            ...newPageData['data'],
           content: "And remember, if you are ever in doubt - ask your Supervisor.",
           content_id: 'induction.' + id + '.page.' + nextId + '.content',
  
          }
     }
     else if(pageType == 'post_login')
     {
          //fix type because it doesn't match the url
          newPageData['url'] = '/post-login-' + nextId;

          newPageData['data'] = {
            ...newPageData['data'],
            questions: [
              {
                 id: 'induction.' + id + '.page.' + nextId + '.question.1',
                 name: "Question 1"
              },
              {
                id: 'induction.' + id + '.page.' + nextId + '.question.2',
                name: "Question 2"
              }
            ]
  
          }
     }
     else if(pageType == 'rules')
     {
        newPageData['data'] = {
          ...newPageData['data'],
        rules:[]

        }        
     }
     else if(pageType == 'assessment')
     {
        //create a new assessment first
        createAssessment({induction_id: id});

        newPageData['assessmentId'] = 0; // we will fill it on API response

        newPageData['data'] = {
          ...newPageData['data'],
          assessmentId: 0 // we will fill it on API response

        } 

     }

     pageData.push(newPageData);

     console.log('newPage',newPageData);

     setInductionPageData(pageData);

     setIsCreateModalOpen(false);

     //re-generate ids
     regeneratePageIds(pageData);
  }

  //halilk: page Ids should be in ascending order in json data
  //because of how the progress is calculated. 
  //this function ensures that by re-assigning ids
  //Note: There is a side effect. When the Ids change, so does the progress of current users
  //They would have to re-take the induction
  function regeneratePageIds(pageData)
  {
     var updatedPageData = [...pageData];
     
     var currentId = 1;
     for(var i = 0; i < updatedPageData.length; i++)
     {
        updatedPageData[i] = {...updatedPageData[i], id: currentId}
        currentId +=1;

         if(updatedPageData[i].type == 'rules')
         {
            //each chapter should also have an id
            var rules = [...updatedPageData[i].data.rules];
            for(var j = 0; j < rules.length; j++)
            {
                rules[j] = {...rules[j], id: currentId}

                currentId +=1;

                updatedPageData[i] = {...updatedPageData[i], data: {...updatedPageData[i].data,rules:rules}}
            }
         }  
     }

     setInductionPageData(updatedPageData);
     console.log('regeneratePageIds',updatedPageData);
  }

  
  //when the create new rule button is pressed
  function createNewRule(item)
  {
    var rules = [...item.data?.rules];

    const newId = rules.length + 1;

    //template
    rules.push({
        "title": "Chapter " + newId,
        "title_id": "induction." + id + ".page." + item?.id + ".rules." + newId + ".title",
        "id": (inductionPageData.length + 1),
        "show_in_menu": true,
        "icon": "https://keepsafe.work/images/icons/drugs_and_alcohol.svg",
        "iconType": "ImageAndText",
        "iconColor": "#28353C",
        "iconBackground": "linear-gradient(95.67deg, #FFC200 0.49%, #EEB954 47.62%, #E0D80E 100.29%)",
        "textColor": "#28353C",
        "background": "linear-gradient(95.67deg, #FFC200 0.49%, #EEB954 47.62%, #E0D80E 100.29%)",
        "ctaColor": "#FFF",
        "ctaBorder": {
            "type": "solid",
            "color": "#FFF"
        },
        "progressColor": "#28353C",
        "progressEmptyColor": "#FFF",
        "border": "linear-gradient(to right, #FFC200, #E0D80E)",
        "sections": []
    });

    console.log('rules',rules);

    //update induction data
    var pageData = [...inductionPageData];

    for(var i=0; i < pageData.length; i++)
    {
        if(pageData[i].id  == selectedPage.id)
        {
            pageData[i] = {...pageData[i],data: {...pageData[i].data, rules: rules}}
        }
    }

    setInductionPageData(pageData);

    //re-generate ids
    regeneratePageIds(pageData);

  }

  function createNewSection()
  {
    var sections = [...selectedRule.sections];

    const newId = sections.length + 1;
    sections.push({
        "title": "New Section",
        "title_id": "induction." + id+ ".page."+selectedPage.id+".rule."+selectedRule.id+".section."+newId+".title",
        "data": {
          "components": []
        }
    });

    console.log('sections',sections);

    //update induction data
    var pageData = [...inductionPageData];

    for(var i=0; i < pageData.length; i++)
    {
        if(pageData[i].id  == selectedPage.id)
        {
            
            var rules = [...pageData[i].data.rules];
            for(var j=0; j < rules.length; j++)
            {
                if(rules[j].id == selectedRule.id)
                {
                  rules[j] = {...rules[j],sections:sections};
                }
            }
            pageData[i] = {...pageData[i],data: {...pageData[i].data, rules: rules}}
        }
    }

    setInductionPageData(pageData);
    
  }

  function deleteSelectedPage()
  {
    var pageData = [...inductionPageData];

    for(var i=0; i < pageData.length; i++)
    {
        if(pageData[i].id == selectedPage.id)
        {
          pageData.splice(i, 1); //delete one item only
        }
    }

    setInductionPageData(pageData);

    //re-generate ids
    regeneratePageIds(pageData);    

    if(pageData.length > 0)
      setSelectedPage(pageData[0]);

  }

  function deleteSelectedChapter(item)
  {
     //get the rules data from the actual page data
     var rules = [];

     var pageData = [...inductionPageData];

      for(var i=0; i < pageData.length; i++)
      {
          if(pageData[i].id == selectedPage.id)
          {
              rules = [...pageData[i].data.rules];
          }
      }

     //now delete the selected one
     console.log('deleteRules - rules',rules);
     console.log('deleteRules - id',item);
     for(var i=0; i < rules.length; i++)
      {
          if(rules[i].id == item.id)
          {
            rules.splice(i, 1); //delete one item only
          }
      }

      //update selected page
      setSelectedPage({...selectedPage,data:{...selectedPage.data, rules: rules}});

      //update global page data
      for(var i=0; i < pageData.length; i++)
      {
          if(pageData[i].id == selectedPage.id)
          {
            pageData[i] = {...selectedPage,data:{...selectedPage.data, rules: rules}}
          }
      }

      setInductionPageData(pageData); 
  
      //re-generate ids
      regeneratePageIds(pageData);

          
  }

//crop modal actions
function closeCropModal()
{
   setIsCropModalOpen(false);
}

function cropImage()
{
   console.log('crop image called');
}



  return (
    <>
      {loading ? (
        <div className={classes.loadingContainer}>
          <ReactLoading
            type={"spin"}
            color={"#20a8d8"}
            height={300}
            width={300}
          />
        </div>
      ) : (
        <div className={classes.InductionEditorContainer}>

          <div className={classes.inductionMenuWrapper}>  
            <h3>Induction</h3>
            
            <div id="div_pageItemWrapper" className={classes.pageItemWrapper}
              
            >
                {inductionPageData.map((item,i) => (
                <div>
                  <PageItem 
                    item={item}
                    isSelected={selectedPage.id == item.id}
                    onClick={() => updateSelectedPage(item)}
                    translations={translations}
                    selectedLanguage={selectedLanguage}
                    id={item?.id}
                    moveItem={moveItem}
                    index={i}
                  />            
                  

                  {(item.data?.rules && item.id == selectedPage.id && item.type=='rules') && (
                    <div className={classes.ruleItemWrapper}>                     
                      {item.data?.rules?.map((item) => (
                         <div>
                          <div onClick={() => updateSelectedRule(item)} className={classes.ruleItem} style={{background:item?.background, color: item?.textColor,borderColor: item?.title == selectedRule.title ? '#44C032' : '#fff'}}>
                            <div className={classes.pageItemHeader}> <img src={pageItemHeaderIcon}/> </div>
                            
                            <div className={classes.ruleIconWrapper}>
                              <div style={{backgroundColor: !isNil(item?.iconBackground) ? item?.iconBackground : item?.color}}>
                                {!isNil(item?.iconType) ? (
                                  <IconComponent
                                    icon={item?.iconType}
                                    color={item?.iconColor ? item?.iconColor : "white"}
                                    size={"80%"}
                                  />
                                ) : (
                                  <img src={item?.icon} />
                                )}
                              </div>
                            </div>
                            
                            {item.title_id ? translations[selectedLanguage.code][item.title_id] : item.title}
                          </div>

                          {item?.title == selectedRule.title && item.sections?.map((sectionItem) => (
                            <div onClick={() => updateSelectedSection(sectionItem)} className={classes.sectionItem} style={{background:item?.background, color: item?.textColor,borderColor: sectionItem?.title == selectedSection.title ? '#44C032' : '#fff'}}>
                               {sectionItem.title_id ? translations[selectedLanguage.code][sectionItem.title_id] : sectionItem.title}
                            </div>
                          ))}

                          {item?.title_id == selectedRule.title_id && (
                            <button
                            id={"save"}
                            type="submit"
                            style={{width: '60px', marginLeft: '40px'}}
                            onClick={createNewSection}
                            disabled={!isNil(loading) ? false : loading}
                            className={classes.darkButton}
                            >
                            +
                            </button>
                          )}

                          </div>
                      
                      ))}   

                      {/* Add a new chapter */}
                      <br />
                      <button
                            id={"new_rule"}
                            type="submit"
                            style={{width: '100px'}}
                            onClick={function(){createNewRule(item)}}
                            disabled={!isNil(loading) ? false : loading}
                            className={classes.darkButton}
                            >
                            New Chapter
                          </button>

                    </div>
                  )}
                </div>
                ))}
            </div>

            
            
            {/*selectedRule.sections && (
              <div className={classes.sectionItemWrapper}>
                {selectedRule.sections?.map((item) => (
                
                    <div onClick={() => updateSelectedSection(item)} className={classes.sectionItem}>{item.title}</div>
                
                ))}      
              </div>
                )*/}
          </div>
            
          <div className={classes.pageEditorWrapper}>                 
            <h3>Editor</h3>
            <div className={classes.pageEditorContainer}>  

                  {languageOptions && (
                    <div className={classes.Container}>
                      <label className={classes.BlockLabel}>
                        Language
                      </label>
                      <Select
                        styles={{
                          control: () => ({
                            width: "100%",
                            height: 44,
                            display: "flex",
                            background: "#f8f8f8",
                            paddingLeft: "10px",
                            color: "#40507e",
                            border: "1px solid #E0E0E0",
                            boxSizing: "border-box",
                            borderRadius: "6px",
                            boxShadow: "1px 1px 1px rgba(0, 0, 0, 0.25)",
                            fontFamily: "Open Sans",
                            fontSize: "14px",
                            fontWeight: "bold",
                          }),
                        }}
                        placeholder={"Select Language"}
                        onChange={(val) => {
                          //setSelectedOption(val.value);
                          setPreviewCounter(0);

                          setSelectedLanguage({
                            code: val.value,
                            name: val.label,
                          });
                          
                        }}
                        tabSelectsValue={selectedLanguage}
                        value={languageOptions.filter(
                          (option) => option.value === selectedLanguage?.code
                        )}
                        options={languageOptions}
                        isSearchable={false}
                      />
                    </div>
                  )} 

                {!selectedSection.title && (
                <Input
                  hasTooltip={true}
                  order="column"
                  value={selectedPage?.title_id ? translations[selectedLanguage.code][selectedPage?.title_id] ? translations[selectedLanguage.code][selectedPage?.title_id] : '' : '' }
                  name={"Heading Text"}
                  characterLimit={250}
                  onChange={(e) => {
                    var newPageUpdate = { ...selectedPagePreview }
                    newPageUpdate.title = e.target.value;
                    
                    //don't update the real data only preview
                    //setSelectedPage(newPageUpdate);
                    setSelectedPagePreview(newPageUpdate);

                    //and translation
                    updateTranslation(selectedLanguage.code, selectedPage?.title_id, e.target.value);
                  }}
                  label={"Heading Text"}
                  placeholder={"Heading Text"}
                  error={""}
                  tooltipText={
                    "Heading text, also known as the page name"
                  }
                />
                )}
                
                {!selectedSection.title && (
                <Input
                  hasTooltip={true}
                  order="column"
                  value={selectedPage?.data?.subtitle_id ? translations[selectedLanguage.code][selectedPage?.data?.subtitle_id] ? translations[selectedLanguage.code][selectedPage?.data?.subtitle_id] : '' : ''}
                  name={"Supporting Text"}
                  characterLimit={250}
                  onChange={(e) => {
                    updatePageData({key:'subtitle',value:e.target.value},true); //only preview
                    updateTranslation(selectedLanguage.code, selectedPage?.data?.subtitle_id, e.target.value);
                  }}
                  label={"Supporting Text"}
                  placeholder={"Supporting Text"}
                  error={""}
                  tooltipText={
                    "Supporting text, appears underneath the heading"
                  }
                />
                )}



                {selectedRule.title && !selectedSection.title  && (
                  <Input
                    hasTooltip={true}
                    order="column"
                    value={selectedRule?.title_id ? translations[selectedLanguage.code][selectedRule?.title_id] ? translations[selectedLanguage.code][selectedRule?.title_id] : '' : ''}
                    name={"Chapter Name"}
                    characterLimit={250}
                    onChange={(e) => {
                      /*
                      var newChapterUpdate = { ...selectedRule }
                      newChapterUpdate.title = e.target.value;
                      setSelectedRule(newChapterUpdate);
                      */
                      //update only the translation
                      updateTranslation(selectedLanguage.code, selectedRule?.title_id, e.target.value);
                    }}
                    label={"Chapter Name"}
                    placeholder={"Chapter Name"}
                    error={""}
                    tooltipText={
                      "Chapter Name"
                    }
                  />
                )}

                {selectedSection.title && (
                  <Input
                    hasTooltip={true}
                    order="column"
                    value={selectedSection?.title_id ? translations[selectedLanguage.code][selectedSection?.title_id] ? translations[selectedLanguage.code][selectedSection?.title_id] : '' : ''}
                    name={"Section Name"}
                    characterLimit={250}
                    onChange={(e) => {
                      var newSectionUpdate = { ...selectedSection }
                      newSectionUpdate.title = e.target.value;
                      setSelectedSection(newSectionUpdate);
                      updateTranslation(selectedLanguage.code, selectedSection?.title_id, e.target.value);
                    }}
                    label={"Section Name"}
                    placeholder={"Section Name"}
                    error={""}
                    tooltipText={
                      "Section Name"
                    }
                  />
                )}
                
                {/* Here we bring the forms based on selected page type */} 

                {selectedPage.type == 'post_login' && (
                  <Prequalification page={selectedPagePreview} 
                                    updatePageData={updatePageData} 
                                    translations={translations} 
                                    selectedLanguage={selectedLanguage} 
                                    updateTranslation={updateTranslation}
                  />
                )}

                {selectedPage.type == 'introduction' && (
                  <Introduction page={selectedPagePreview} 
                                updatePageData={updatePageData} 
                                translations={translations} 
                                selectedLanguage={selectedLanguage} 
                                updateTranslation={updateTranslation}
                                setImageToCrop={setImageToCrop}
                                setIsCropModalOpen={setIsCropModalOpen}
                                croppedImage={croppedImage}
                  />
                )}

                {selectedPage.type == 'welcome' && (
                  <Video page={selectedPagePreview} updatePageData={updatePageData} />
                )}

                {selectedPage.type == 'all-chapters-complete' && (
                  <AllChaptersCompleteForm page={selectedPagePreview} 
                                           updatePageData={updatePageData} 
                                           translations={translations} 
                                           selectedLanguage={selectedLanguage} 
                                           updateTranslation={updateTranslation}                  
                  />
                )}

                {selectedPage.type == 'complete' && (
                  <InductionCompleteForm page={selectedPagePreview} 
                                         updatePageData={updatePageData}
                                         translations={translations} 
                                         selectedLanguage={selectedLanguage} 
                                         updateTranslation={updateTranslation}
                  />
                )}

                {selectedPage.type == 'assessment' && (
                  <AssessmentForm page={selectedPagePreview} 
                                  updatePageData={updatePageData}
                                  translations={translations} 
                                  selectedLanguage={selectedLanguage} 
                                  updateTranslation={updateTranslation}
                                  setEditData={setEditData}
                  />
                )}

                {selectedPage.type == 'site-rules' && (
                  <RulesForm page={selectedPagePreview} 
                             updatePageData={updatePageData} 
                             translations={translations} 
                             selectedLanguage={selectedLanguage} 
                             updateTranslation={updateTranslation}
                             induction_id={id}
                  
                  />
                )}

                {selectedPage.type == 'rules' && selectedSection.title && (
                  <ChaptersForm page={selectedPagePreview} 
                                chapter={selectedRule} 
                                section={selectedSection} 
                                updatePageData={updatePageData} 
                                setSelectedSection={setSelectedSection} 
                                translations={translations} 
                                selectedLanguage={selectedLanguage} 
                                updateTranslation={updateTranslation}
                                setEditData={setEditData}
                                induction_id={id}
                  />
                )}

                {selectedPage.type == 'rules' && selectedRule.title && !selectedSection.title && (
                  <ChapterSettingsForm page={selectedPagePreview} 
                                chapter={selectedRule} 
                                updatePageData={updatePageData} 
                                translations={translations} 
                                selectedLanguage={selectedLanguage} 
                                updateTranslation={updateTranslation}
                                setEditData={setEditData}
                                induction_id={id}
                                updateSelectedRule={updateSelectedRule}
                  />
                )}




                <br />
                <button
                    id={"save"}
                    type="submit"
                    style={{width: '250px'}}
                    onClick={handleSubmit}
                    disabled={!isNil(loading) ? false : loading}
                    className={classes.darkButton}
                  >
                    Save
                </button>

                <br />
                {selectedPage.id && !selectedRule.id && (
                <button
                    id={"delete"}
                    type="submit"
                    style={{width: '250px'}}
                    onClick={deleteSelectedPage}
                    disabled={!isNil(loading) ? false : loading}
                    className={classes.deleteButton}
                  >
                    Delete this page
                </button>
                )}

                {selectedRule.id && (
                  <button
                    id={"delete"}
                    type="submit"
                    style={{width: '250px'}}
                    onClick={function(){deleteSelectedChapter(selectedRule)}}
                    disabled={!isNil(loading) ? false : loading}
                    className={classes.deleteButton}
                  >
                    Delete this chapter
                </button>
                )}

                <br />





            </div>
          </div>

          <div className={classes.pagePreviewWrapper}>
            <h3>Preview</h3>
            <div className={classes.pagePreviewContainer}>
              <div className={classes.pagePreviewContent}>
                  <div className={classes.pagePreviewHeaderWrapper}>
                    <Header
                    menu={false}
                    open={false}
                    setOpen={function(){}}
                  />
                  </div>

                  {/* These are different page components for preview - opened in preview mode */}

                  {selectedPage?.type == "introduction" && previewCounter > 0 && (
                    <div>
                        <Introductory selectedInduction={induction} isPreview={true} pageData={selectedPagePreview} />
                    </div>
                  )}

                  {selectedPage?.type == "welcome" && (
                    <div>
                        <Welcome selectedInduction={induction} isPreview={true} pageData={selectedPagePreview} />
                    </div>
                  )}

                  {selectedPage?.type == "take-a-look" && (
                    <div>
                        <LookAround selectedInduction={induction} isPreview={true} pageData={selectedPagePreview} />
                    </div>
                  )}

                  {selectedPage?.type == "all-chapters-complete" && (
                    <div>
                        <AllChaptersComplete selectedInduction={induction} isPreview={true} pageData={selectedPagePreview} />
                    </div>
                  )}

                  {selectedPage?.type == "site-rules" && previewCounter > 0 && (
                    <div>
                        <SiteRules selectedInduction={induction} isPreview={true} pageData={selectedPagePreview} />
                    </div>
                  )}

                  {selectedPage?.type == "complete" && previewCounter > 0 &&  (
                    <div>
                        <Complete selectedInduction={induction} isPreview={true} pageData={selectedPagePreview} />
                    </div>
                  )}


                  {selectedPage?.type == "assessment" && (
                    <div>
                        <AssesmentPage selectedInduction={induction} isPreview={true} pageData={selectedPagePreview} />
                    </div>
                  )}


                  {selectedPage?.type == "post_login" && (
                    <div>
                        <PostLogin selectedInduction={induction} isPreview={true} pageData={selectedPagePreview} />
                    </div>
                  )}




                  {selectedPage?.type == "rules" && selectedSection?.title == '' && (
                      
                    <div>
                        <Induction selectedInduction={induction} 
                                   induction={induction} isPreview={true} 
                                   pageData={selectedPagePreview} 
                                   history={history} 
                        />
                    </div>
                      
                  )}

                  {selectedSection && previewCounter > 0 && (
                    <div>
                        {selectedSection.data?.components?.map((item) =>
                            
                          item.type != 'card_sort__' ? getComponent(item.type, {
                            ...item,
                            title: translations[selectedLanguage.code][item?.title_id] ? translations[selectedLanguage.code][item?.title_id] : '-',
                            header: translations[selectedLanguage.code][item?.header_id] ? translations[selectedLanguage.code][item?.header_id] : '-',
                            button_label: translations[selectedLanguage.code][item?.button_label_id],
                            conclusion_title: translations[selectedLanguage.code][item?.conclusion_title_id],
                            conclusion_content: translations[selectedLanguage.code][item?.conclusion_content_id],
                            success_modal_title: translations[selectedLanguage.code][item?.success_modal_title_id],
                            success_modal_description: translations[selectedLanguage.code][item?.success_modal_description_id],
                            category_title: translations[selectedLanguage.code][item?.category_title_id] ? translations[selectedLanguage.code][item?.category_title_id] : '-',
                            content: item.type != 'carousel' ? translations[selectedLanguage.code][item?.content_id] : prepareTranslatedCarousel(item.content),
                            hotspots: item.type == 'hotspots' ? prepareTranslatedHotspots(item.hotspots) : '',
                            cards: item.type == 'flipcard' ? prepareTranslatedFlipcards(item.cards) : item.type == 'card_sort' ? prepareTranslatedCardsort(item.cards) : item?.cards
                          }, () => {},true) : ''
                          
                        )}
                    </div>
                  )}

              </div>
            </div>
          </div>

          
          <div className={classes.buttonContainer}>
            <div className={classes.buttonWrapper}>
              <div style={{ justifyContent: "flex-end" }} className={classes.flex}>

                <button
                  disabled={false}
                  className={classes.darkButton}
                  onClick={() => {
                    if(induction?.status == 1)
                      updateInduction({'id': id, 'status': 0})
                    else
                      updateInduction({'id': id, 'status': 1})
                  }}
                >
                  {induction?.status == 1 ? "Move to Draft" : "Publish"}
                </button>
                
              </div>
              &nbsp; 
              <div style={{ justifyContent: "flex-end" }} className={classes.flex}>
                <button
                  disabled={false}
                  className={classes.darkButton}
                  onClick={() => {
                     setIsCreateModalOpen(true);
                  }}
                >
                  {"Create a page"}
                </button>
              </div>

            </div>
          </div>          


          

            
        </div>
        

      )}

      <RouteLeavingGuard
        // When should shouldBlockNavigation be invoked,
        // simply passing a boolean
        // (same as "when" prop of Prompt of React-Router)
        confirmModal={true}
        when={editData == true}
        // Navigate function
        navigate={(path) => history.push(path)}
        // Use as "message" prop of Prompt of React-Router
        shouldBlockNavigation={(location) => {
          return true;
        }}
      />

      <Modal
        open={isThereUnsavedEdits}
        cancel={closeModal}
        action={modalAction}
        deleteTitle={"You have unsaved changes"}
        deleteDescription={
          "If you continue, your changes will be lost. Do you want to continue?"
        }
        buttonTitle={"Continue"}
      />

      <Modal
        open={isCreateModalOpen}
        cancel={closeCreateModal}
        action={createPage}
        type={'editor'}
        buttonTitle={"Create"}
        updateData={function(key,value){
           if(key == 'type')
              setPageType(value);
        }}
      />

      <CropModal
        open={isCropModalOpen}
        cancel={closeCropModal}
        action={cropImage}
        buttonTitle={"Crop"}
        image={imageToCrop}
        setCroppedImage={setCroppedImage}
      />



    </>
  );
};

const mapStateToProps = createStructuredSelector({
  loading: makeSelectLoading(),
  induction: makeSelectInductionDetail(),
  languages: makeSelectLanguages(),
  assessmentDetails: makeSelectAssessmentDetails(),
});
const mapDispatchToProps = (dispatch) => {
  return {
    getInductions: (val) => dispatch(getInductionDetailReq(val)),
    updateInduction: (val) => dispatch(updateInductionReq(val)),
    createAssessment: (val) => dispatch(createAssessmentReq(val)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(InductionEditor);
